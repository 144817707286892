function Accessibility({ cards, handleSingle }) {
  return (
    <div className="accessibility">
      {cards.map((card, i) => {
        console.log(card);
        return (
          <div
            key={i}
            className="accessibility-item"
            onClick={() => handleSingle(card)}
          >
            {card.customName ? card.customName : card.suiteNumber}
          </div>
        );
      })}
    </div>
  );
}

export default Accessibility;
