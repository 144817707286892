import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import boardService from "../../../services/board.service";
import cloudinaryService from "../../../services/cloudinary.service";
import suiteService from "../../../services/suite.service";

function useGeneralDirectoryData() {
  const [slides, setSlides] = useState([]);
  const [suites, setSuites] = useState({});
  const [boardData, setBoardData] = useState({});
  const [defaultLogo, setDefaultLogo] = useState("");
  const [rateLimitHit, setRateLimitHit] = useState(false);
  const [rateLimitRetry, setRateLimitRetry] = useState(0);
  const params = useParams();

  const getSlides = useCallback(() => {
    // if the rate limit has already been hit, do nothing
    if (rateLimitHit) return;

    if (params) {
      const folder = params.dir.split(" ").join("-").toLowerCase();
      boardService
        .getBoardByName({ board_name: params.dir })
        .then((res) => {
          setBoardData(res.data);
          cloudinaryService
            .getSlides({ assetsFolder: folder })
            .then((slidesRes) => {
              const slides = slidesRes.data.resources;
              if (!slides.length) {
                throw new Error("NO SLIDES");
              }
              setSlides(slides);
            })
            .catch((err) => {
              console.log("error fetching slides: ", err);
            });
        })
        .catch((err) => {
          const retry = err.response.headers["retry-after"];
          if (retry) {
            const retryTime = parseInt(retry);
            setRateLimitHit(true);
            setRateLimitRetry(retryTime);
          } else {
            console.log("error getting board by name", err);
          }
        });
    }
  }, [params.dir, rateLimitHit]);

  const getLogo = useCallback(() => {
    if (!params) {
      console.log("NO PARAMS");
      return;
    }
    const folder = params.dir.split(" ").join("-").toLowerCase();
    cloudinaryService
      .getLogos({ assetsFolder: folder })
      .then((res) => {
        const logos = res.data.resources;
        if (!logos.length) {
          throw new Error("NO LOGOS");
        }
        setDefaultLogo(logos[0].secure_url);
      })
      .catch((err) => {
        console.log("error fetching logos: ", err);
      });
  }, [params.dir]);

  const grabBoardData = useCallback(() => {
    // if the rate limit has already been hit, do nothing
    if (rateLimitHit) return;

    boardService
      .getBoardByName({ board_name: params.dir })
      .then((res) => {
        setBoardData(res.data);
      })
      .catch((err) => {
        const retry = err.response.headers["retry-after"];
        if (retry) {
          const retryTime = parseInt(retry);
          setRateLimitHit(true);
          setRateLimitRetry(retryTime);
        } else {
          console.log("error getting board by name", err);
        }
      });
  }, [params.dir, rateLimitHit]);

  const getBoardSuites = useCallback(() => {
    const boardIdNumber = boardData.id;
    if (!boardIdNumber) return;

    suiteService
      .getSuitesByBoard({ boardId: boardIdNumber })
      .then((res) => {
        const allSuites = res.data;
        if (!allSuites.length) {
          throw new Error("NO SUITES");
        }

        const suitesWithCardInfo = {};

        allSuites.forEach((suite) => {
          const { custom_name: customName, suite_number: suiteNumber } = suite;
          let suiteName = "";
          if (customName) suiteName = customName.toLowerCase();
          else {
            suiteName = suiteNumber < 10 ? "0" + suiteNumber : "" + suiteNumber;
          }

          const cards = suite.cards;
          const firstCard = cards.length
            ? cards[0]
            : { about: "", business_name: "" };

          suitesWithCardInfo[suiteName] = {
            contact: firstCard.business_name,
            description: firstCard.about,
          };
        });

        setSuites(suitesWithCardInfo);
      })
      .catch((err) => {
        console.log("Error fetching suites: ", err);
      });
  }, [params.dir, boardData.id]);

  useEffect(() => {
    getSlides();
    getLogo();
    grabBoardData();
  }, [params.dir]);

  useEffect(() => {
    getBoardSuites();
  }, [boardData.id]);

  return [
    boardData,
    slides,
    defaultLogo,
    suites,
    rateLimitHit,
    setRateLimitHit,
    rateLimitRetry,
    setRateLimitRetry,
  ];
}

export default useGeneralDirectoryData;
