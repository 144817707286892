import { BarLoader } from "react-spinners";
import { Container } from "react-bootstrap";

const override = {
  width: "90%",
  borderRadius: "5px",
};

export default function LazyLoadingBar() {
  return (
    <div className="loading-overlay">
      <BarLoader
        color="#09527f"
        height={8}
        cssOverride={override}
        speedMultiplier={0.5}
      />
    </div>
  );
}
