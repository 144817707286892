import React, { useEffect, useRef } from "react";
import { useGeneralDirectoryContext } from "./context/GeneralDirectoryContext";
import "../../pages/generalDirectory/generalDirectory.css";

const delay = 10000;

function Carousel() {
  const { slides, slideIndex, updateSlideIndex } = useGeneralDirectoryContext();
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    if (!slides.length) return;

    resetTimeout();
    timeoutRef.current = setTimeout(() => updateSlideIndex(), delay);

    return () => {
      resetTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides.length, slideIndex]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-slideIndex * 100}%, 0, 0)` }}
      >
        {slides.map((slide, index) => {
          return (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              key={`slide-${index}`}
              className="slide"
              src={slide.secure_url}
            ></img>
          );
        })}
      </div>
    </div>
  );
}

export default Carousel;
