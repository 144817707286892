/* eslint-disable jsx-a11y/alt-text */
import { memo } from "react";
import { FaWheelchair } from "react-icons/fa";
import ada from "./images/ada.jpg";
import Accessibility from "./Accessibility";
import { filterImages } from "../../services/constants";
import Filter from "./Filter";
function Footer(props) {
  return (
    <div className="dir-footer">
      <div className="filters">
        {filterImages.map((filter, i) => {
          return (
            <Filter
              key={`filter-${i}`}
              handleFilter={props.handleFilter}
              name={filter.name}
              image={filter.image}
            />
          );
        })}
      </div>
      <div className="ada-container">
        <img src={ada} className="ada" onClick={props.handleAda}></img>
      </div>
    </div>
  );
}

export default memo(Footer);
