import getRetryString from "../../helpers/getRetryString";
import "./rate-limit-hit.css";

export default function RateLimitHit({ numSeconds }) {
  let retryTime = "";
  if (typeof numSeconds === "number") {
    retryTime = getRetryString(numSeconds);
  } else {
    retryTime = getRetryString(600);
  }

  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center rate-limit-container">
      <div className="w-25 h-auto d-flex flex-column justify-content-center align-items-center bg-light border border-2 border-primary rounded shadow-sm">
        <h2 className="fs-1 mt-2 mb-4">Too Many Requests</h2>
        <p className="fs-2 mb-1">Please retry in:</p>
        <p className="fs-2 mb-4">{retryTime}</p>
        <button
          className="btn btn-primary mb-3 px-5 py-2"
          onClick={() => window.location.reload()}
        >
          Retry
        </button>
      </div>
    </div>
  );
}
