import { createContext, useContext } from "react";

const GeneralDirectoryContext = createContext(null);

export function useGeneralDirectoryContext() {
  const context = useContext(GeneralDirectoryContext);

  if (context == null) {
    throw new Error(
      "GeneralContext Error: component must be rendered as a child of GeneralContextProvider"
    );
  }

  return context;
}

export default GeneralDirectoryContext;
