import React from "react";
import QRCode from "react-qr-code";
import "../../pages/salonDirectory/directory.css";

function QrCode(props) {
  return (
    <>
      <div className="">
        <QRCode
          size={props.size}
          value={props.value ? props.value : "https://adiuvollc.com"}
        />
      </div>
    </>
  );
}

export default QrCode;
