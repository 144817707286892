import axios from "axios";
import authHeader from "./auth.header";
import { useNavigate } from "react-router-dom";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class SuiteService {
  getSuitesByBoard(board) {
    return axios.post(API + "suites", board, { headers: authHeader() });
  }
  updateSuite(data) {
    return axios.post(API + "update-suite", data, {
      headers: authHeader(),
    });
  }
  getSuiteMap(id) {
    return axios.post(API + "suites/get-suite-map", id);
  }

  setCustomName(id, name) {
    let data = { id, name };
    return axios.post(API + "suites/set-custom-name", data, {
      headers: authHeader(),
    });
  }
}
export default new SuiteService();
