/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useRef, memo, useMemo } from "react";
import cardService from "../../services/card.service";

const delay = 10000;

function InstaSlideshow({ token }) {
  const [index, setIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    console.log("Token: ", token);
    if (!token) {
      return;
    }
    cardService
      .getInstaImages(token)
      .then((res) => {
        if (res) {
          setSlides(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="insta-slideshow">
      <div
        className="insta-slider"
        style={{ transform: `translate3d(${-index * 290}px, 0, 0)` }}
      >
        {slides.map((slide, i) => {
          const type = slide.media_type;

          if (type === "VIDEO") {
            return (
              <div
                className="insta-content-holder"
                key={slide.media_url}
              >
                <video
                  className="insta-slide"
                  autoPlay
                  loop
                  src={slide.media_url}
                  muted
                ></video>
              </div>
            );
          } else {
            return (
              <div
                className="insta-content-holder"
                key={slide.media_url}
              >
                <img
                  key={`slide-${i}`}
                  className="insta-slide"
                  src={slide.media_url}
                ></img>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default memo(InstaSlideshow);
