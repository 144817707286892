function AbsoluteVideo({
  customClass,
  embedId,
  coords,
  height,
  width,
  zIndex,
}) {
  return (
    <div
      className={`video ${customClass}`}
      style={{ top: coords.top, left: coords.left, zIndex: zIndex }}
    >
      <div className="iframe-holder">
        <iframe
          height={height}
          width={width}
          src={`https://www.youtube.com/embed/videoseries?iv_load_policy=3&mute=1&loop=1&controls=0&fs=0&modestbranding=1&autohide=1&rel=0&disablekb=1&frameborder=0&showinfo=0&autoplay=1&amp;list=${embedId}`}
          title="YouTube video player"
        />
      </div>
    </div>
  );
}

export default AbsoluteVideo;
