import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class CardService {
  createCard(data) {
    return axios.post(API + "create-card", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }
  updateCard(data) {
    return axios.post(API + "update-card", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }

  createSimpleCard(data) {
    return axios.post(API + "create-simple-card", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }
  updateSimpleCard(data) {
    return axios.post(API + "update-simple-card", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }
  deleteCard(id) {
    return axios.post(API + "delete-card", id, {
      headers: authHeader(),
    });
  }

  setInstaToken(id, token) {
    const data = { id, token };

    return axios.post(API + "card/set-insta-token", data, {
      headers: authHeader(),
    });
  }

  getInstaImages(token) {
    const instaApi =
      "https://graph.instagram.com/me/media?fields=media_type,media_url,permalink&access_token=";

    return new Promise((resolve, reject) => {
      axios
        .get(`${instaApi}${token}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
export default new CardService();
