import { useState, useEffect, memo, useMemo } from "react";
import facial from "./images/facial.png";
import { filterImages } from "../../services/constants";
import { useNavigate, useLocation } from "react-router-dom";

function Cards(props) {
  const [cardList, setCardList] = useState([]);
  const [multiplier, setMultiplier] = useState(1.0);
  const [heightMultiplier, setHeightMultiplier] = useState(0);

  const generateCards = (num) => {
    if (num <= 25) {
      setMultiplier(1.75);
    } else if (num <= 49 && num > 25) {
      setMultiplier(1.5);
    } else if (num <= 63 && num > 49) {
      setMultiplier(1.25);
    } else if (num <= 80 && num > 63) {
      setMultiplier(1.11);
    } else if (num <= 100 && num > 80) {
      setMultiplier(1.0);
    }
    let tempArr = [];
    for (let i = 1; i <= num; i++) {
      tempArr.push("hello");
    }
    setCardList(tempArr);
  };
  const callMultiplier = (array) => {
    const num = array.length;
    if (num <= 12) {
      setMultiplier(3);
    } else if (num <= 25 && num > 12) {
      setMultiplier(2.25);
    } else if (num <= 49 && num > 25) {
      setMultiplier(1.5);
    } else if (num <= 63 && num > 49) {
      setMultiplier(1.25);
    } else if (num <= 80 && num > 63) {
      setMultiplier(1.11);
    } else if (num <= 100 && num > 80) {
      setMultiplier(1);
    }
  };

  useEffect(() => {
    if (props.cards) {
      let newList = props.cards.sort((a, b) => {
        // if the custom suite name is a number, sort it
        if (a.customName || b.customName) {
          if (a.customName && !b.customName) {
            return a.customName - b.suiteNumber;
          } else {
            return a.suiteNumber - b.customName;
          }
        } else {
          return a.suite_number - b.suite_number;
        }
      });
      setCardList(newList);
      callMultiplier(props.cards);
    }
  }, [props.cards]);

  const newCardList = useMemo(() => {
    if (props.cards.length) {
      return props.cards.sort((a, b) => {
        if (a.suiteNumber && b.suiteNumber) {
          return a.suiteNumber - b.suiteNumber;
        } else return 0;
      });
    } else return [];
  }, [props.cards]);

  const pickCategory = (categories) => {
    // console.log("categories: ", categories);

    if (categories && categories.length > 0) {
      let cat = categories[0].name.toLowerCase();
      let foundImage = filterImages.find((e) => e.name.toLowerCase() === cat);
      // console.log(cat);
      // console.log("foundImage: ", foundImage);
      if (foundImage) {
        return foundImage.image;
      }

      if (foundImage) {
        return foundImage.image;
      }
    } else {
      return false;
    }
  };
  return (
    <div className="d-flex justify-content-center">
      <div className="card-list-container">
        {newCardList.map((card, i) => {
          // console.log(card);
          let categories = card.categories.map((e) => e.name.toLowerCase());
          if (
            categories.includes(props.filter.toLowerCase()) ||
            props.filter == "all"
          ) {
            return (
              <div
                onClick={() => props.handleSingle(card)}
                key={i}
                style={{
                  width: 160 * multiplier,
                  height:
                    47 * (heightMultiplier ? heightMultiplier : multiplier),
                }}
                className=" card-box"
              >
                <img
                  style={{
                    width: 47 * multiplier,
                    height:
                      47 * (heightMultiplier ? heightMultiplier : multiplier),
                  }}
                  className="card-img-box"
                  alt="card logo"
                  src={
                    card.logo
                      ? card.logo
                      : pickCategory(card.categories)
                      ? pickCategory(card.categories)
                      : facial
                  }
                ></img>
                <div className="card-text-container">
                  <div className="small-card-heading">
                    <div className="heading-business-name">
                      {card.business_name}
                    </div>
                  </div>
                  <div className="small-card-footer">
                    <div className="footer-contact-name">
                      {card.contact_name}
                    </div>
                    <div>
                      {card.customName ? card.customName : card.suiteNumber}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default memo(Cards);
