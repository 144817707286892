/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useMemo } from "react";
import QRCode from "./QRCode";
import facial from "./images/facial.png";
import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaArrowCircleLeft,
} from "react-icons/fa";
import InstaSlideshow from "./InstaSlideshow";
import Video from "./Video";
import suiteService from "../../services/suite.service";
import tpService from "../../services/tp.service";
import "../../pages/salonDirectory/directory.css";
import "./single.css";
import { filterImages } from "../../services/constants";
import tokenService from "../../services/token.service";
import homeIcon from "./images/homeIcon.png";

function Single({ card, allSitemaps, playlist, handleBack }) {
  const business = card;
  const [token, setToken] = useState(null);

  const sitemapUrl = useMemo(() => {
    // console.log(allSitemaps);
    if (business.customName) {
      return allSitemaps[business.customName];
    } else {
      let suiteString = business.suiteNumber.toString();
      if (parseInt(suiteString) < 10) {
        suiteString = "0" + suiteString;
      }
      return allSitemaps[suiteString];
    }
  }, [allSitemaps, business]);

  useEffect(() => {
    if (!card.tokens.length) {
      return;
    }
    const instaToken = card.tokens[0].token || "";

    setToken(instaToken);
    console.log(card);
  }, [card]);

  return (
    <div className="single text-light w-100">
      <div className="single-top-row">
        <div className="single-banner">
          <div className="single-contact-info">
            <img
              className="single-card-image"
              src={business.logo ? business.logo : facial}
            ></img>
            <div className="single-contact-details">
              <h2 className="single-header">{business.business_name}</h2>
              <div className="single-business-info">
                <ul className="single-list unstyled-list">
                  <li className="single-list-item">
                    <FaUser />
                    <span className="mx-3 single-list-info">
                      {business.contact_name}
                    </span>
                  </li>
                  <li className="single-list-item">
                    <FaPhone />
                    <span className="mx-3 single-list-info">
                      {business.phone}
                    </span>
                  </li>
                  <li className="single-list-item">
                    <FaEnvelope />
                    <span className="mx-3 single-list-info">
                      {business.email}
                    </span>
                  </li>
                  {/* <li>
                    <FaGlobe />
                    <span className="mx-3">{business.website}</span>
                  </li> */}
                </ul>
                <div className="single-qr">
                  <QRCode
                    size={125}
                    value={business.website}
                  />
                </div>
              </div>
            </div>
          </div>
          <InstaSlideshow token={token || ""} />
          <div
            id="videoContainer"
            className="video-container single-video"
          >
            <div className="iframe-container"></div>
          </div>
        </div>
      </div>
      <div className="single-body">
        <div className="single-sitemap-container">
          <img
            className="single-sitemap"
            src={sitemapUrl}
          />
        </div>
        <div className="single-about-services-container">
          <div className="single-about">
            <h2
              className="single-about-services-header"
              style={{ position: "relative", top: "0rem" }}
            >
              About
            </h2>
            <p>{business.about}</p>
          </div>
          <div className="single-services">
            <h2 className="single-about-services-header">Services</h2>
            <div className="services-categories">
              {card.categories.map((category, i) => {
                let image = filterImages.find(
                  (image) => image.name === category.name.toLowerCase()
                );

                return (
                  <div
                    className="category"
                    key={"category-" + i}
                  >
                    <img src={image ? image.image : filterImages[10].image} />
                    <p>{category.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <p>{business.services}</p> */}
        </div>
      </div>
      <div className="single-bottom-row">
        <div
          className="home-button bg-light"
          onClick={handleBack}
        >
          <img src={homeIcon} />
        </div>
      </div>
    </div>
  );
}

export default Single;
