import ReactDOM from "react-dom/client";
import "./App.css";
import MyRoutes, { allValidPaths } from "./services/MyRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./components/Navigation";
import { useEffect, useState, useLayoutEffect, Suspense } from "react";
import authService from "./services/auth.service";
import PageNotFound from "./pages/pageNotFound";
import LazyLoadingBar from "./components/LazyLoadingBar";
import "../src/pages/dash/dash.css";
import "../src/index.css";
import "../src/pages/auth/login.css";
import "../src/components/forms/forms.css";
import "../src/components/directory/single.css";
import "../src/pages/auth/instagram.css";
import "../src/pages/salonDirectory/directory.css";
import "../src/pages/dash/healthChecker/components/pingsTable.css";
import "../src/pages/pageNotFound/page-not-found.css";
import "../src/components/rateLimitHit/rate-limit-hit.css";
import "../src/pages/digitalSignage/digitalSignage.css";
import "../src/pages/generalDirectory/generalDirectory.css";

const isNoNavPage = (path) => {
  const pathSplit = path.split("/")[1];

  return (
    path === "/" ||
    pathSplit.includes("login") ||
    pathSplit === "directory" ||
    pathSplit.includes("digital-signage") ||
    pathSplit.includes("suite-instagram") ||
    pathSplit.includes("general-directory") ||
    pathSplit.includes("instagram") ||
    pathSplit.includes("test") ||
    !allValidPaths.has(pathSplit)
  );
};

const isAuthPage = (path) => {
  const pathSplit = path.split("/")[1];

  if (path === "/") {
    return true;
  } else if (!allValidPaths.has(pathSplit)) {
    return false;
  } else {
    const isNonAuthPage =
      pathSplit === "directory" ||
      pathSplit.includes("digital-signage") ||
      pathSplit.includes("general-directory") ||
      pathSplit.includes("instagram") ||
      pathSplit.includes("test");

    return !isNonAuthPage;
  }
};

function App() {
  const [showNav, setHideNav] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [renderNotFound, setRenderNotFound] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  function logout() {
    authService.logout();
    navigate("/login");
  }

  useLayoutEffect(() => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));

    if (isNoNavPage(location.pathname)) {
      setHideNav(false);
    } else {
      setHideNav(true);
    }

    if (isAuthPage(location.pathname)) {
      if (user) {
        const decodedJwt = parseJwt(user.accessToken);

        if (!decodedJwt || decodedJwt.exp * 1000 < Date.now()) {
          logout();
        }
      } else if (
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/"
      ) {
        setRenderNotFound(true);
        // window.location.href = "/login";
      }
    }
    setIsLoading(false);
  }, [location.pathname]);

  useEffect(() => {
    if (renderNotFound) {
      setHideNav(false);
    }
  }, [renderNotFound]);

  return (
    <div className="content">
      {showNav ? <Navigation /> : null}
      <Suspense fallback={<LazyLoadingBar />}>
        {!isLoading && !renderNotFound && <MyRoutes />}
      </Suspense>
      {!isLoading && renderNotFound && (
        <PageNotFound page={location.pathname} />
      )}
    </div>
  );
}
export default App;
