export const filterImages = [
  { name: "botox", image: require("../components/directory/images/botox.png") },
  {
    name: "boutique",
    image: require("../components/directory/images/boutique.png"),
  },
  {
    name: "facial",
    image: require("../components/directory/images/facial.png"),
  },
  {
    name: "barber",
    image: require("../components/directory/images/barber.png"),
  },
  { name: "hair", image: require("../components/directory/images/hair.png") },
  { name: "laser", image: require("../components/directory/images/laser.png") },
  {
    name: "lashes & brows",
    image: require("../components/directory/images/lashes-and-brows.png"),
  },
  { name: "nails", image: require("../components/directory/images/nails.png") },
  {
    name: "wax & tan",
    image: require("../components/directory/images/wax-and-tan.png"),
  },
  {
    name: "massages & body wellness",
    image: require("../components/directory/images/wellness.png"),
  },
  {
    name: "tattoo",
    image: require("../components/directory/images/tattoo.png"),
  },
  {
    name: "all",
    image: require("../components/directory/images/all.png"),
  },
];
