import { useState, useEffect } from "react";
import ConfirmPanic from "./modals/ConfirmPanic";
import panicModeService from "../services/panicMode.service";
import useAsyncTransition from "../hooks/useAsyncTransition";

export default function PanicButton() {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isPanicState, setIsPanicState] = useState(false);
  const [isPending, startTransition] = useAsyncTransition();
  const [loadingError, setLoadingError] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  const handleOpenConfirm = () => setIsConfirmOpen(true);
  const handleCloseConfirm = () => (isPending ? null : setIsConfirmOpen(false));

  const handlePanicConfirm = () => {
    if (isPending) return;
    setUpdateError(false);
    startTransition(async () => {
      try {
        await panicModeService.setPanicMode(!isPanicState);
        setIsPanicState((prev) => !prev);
      } catch (err) {
        console.log(err);
        setUpdateError(true);
      }
      handleCloseConfirm();
    });
  };

  const getPanicState = async () => {
    try {
      const panicMode = (await panicModeService.getPanicMode()).data;
      setIsPanicState(panicMode);
    } catch (err) {
      setLoadingError(true);
    }
  };

  useEffect(() => {
    getPanicState();
  }, []);

  return (
    <div className="">
      {isConfirmOpen && (
        <>
          <ConfirmPanic
            isOpen={isConfirmOpen}
            handleClose={handleCloseConfirm}
            handleConfirm={handlePanicConfirm}
            isPanicModeActive={isPanicState}
            isPending={isPending}
          />
        </>
      )}
      <div className="d-flex align-items-center gap-4">
        {loadingError ? null : !isPanicState ? (
          <button
            className="btn btn-danger"
            onClick={handleOpenConfirm}
            disabled={loadingError}
          >
            PANIC MODE
          </button>
        ) : (
          <button
            className="btn btn-success"
            onClick={handleOpenConfirm}
          >
            CLEAR PANIC
          </button>
        )}

        {loadingError && (
          <div
            className="alert alert-danger mb-0"
            role="alert"
          >
            Failed to fetch panic mode
          </div>
        )}
        {updateError && (
          <div
            className="alert alert-danger mb-0"
            role="alert"
          >
            Failed to update panic mode
          </div>
        )}
      </div>
    </div>
  );
}
