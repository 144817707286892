import axios from "axios";

const API =
  "https://graph.instagram.com/me/media?fields=media_type,permalink,media_url&access_token=";
class TPService {
  fetchInstagramImages(token) {
    return axios.get(API + token);
  }
}
export default new TPService();
