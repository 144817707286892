import React from "react";
import "../../pages/generalDirectory/generalDirectory.css";
import Marquee from "react-fast-marquee";

function PoweredBy() {
  return (
    <div className="powered-container">
      <div className="powered-by">
        <span className="powered-text">Powered by www.adiuvollc.com</span>
      </div>
    </div>
  );
}

export default PoweredBy;
