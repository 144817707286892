export default function getRetryString(numSeconds) {
  const fullMinutes = numSeconds / 60;
  const minutes = Math.floor(fullMinutes);

  const secondsRemainder = fullMinutes % 1;
  const fullSeconds = Math.round(secondsRemainder * 60);
  const seconds = fullSeconds > 10 ? "" + fullSeconds : "0" + fullSeconds;

  return `${minutes} minutes, ${seconds} seconds`;
}
