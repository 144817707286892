import { useState, memo } from "react";
import ImageSlider from "./ImageSlider";
import "../../pages/salonDirectory/directory.css";
import moment from "moment";
import QRCode from "./QRCode";
import Contact from "./Contact";
import Featured from "./Featured";
import "animate.css";
import Card from "react-bootstrap/Card";
import { useParams } from "react-router-dom";
import getContrastTextColor from "../../services/getContrastTextColor";

function ScreenSaver({
  accentColor,
  clicked,
  website,
  instaImages,
  board,
  cards,
}) {
  const params = useParams();
  const [background, setBackground] = useState("");

  return (
    <div
      className="screensaver"
      onClick={clicked}
    >
      <div
        className="screensaver-side"
        style={{
          backgroundColor: accentColor,
          color: getContrastTextColor(accentColor),
        }}
      >
        <div
          className="directory-time"
          style={{ color: getContrastTextColor(accentColor) }}
        >
          {moment().format("MMM Do, YYYY")}
          <br></br>
          {moment().format("LT")}
        </div>

        <Card
          style={{
            backgroundColor: "#F8F8FF",
            color: "black",
            boxShadow: "5px 3px 16px rgba(0, 0, 0)",
          }}
          className="contact-card"
        >
          <Card.Body>
            <Card.Text>
              {
                <Contact
                  header={
                    board.custom_contact_header
                      ? board.custom_contact_header
                      : "For information about renting suites, contact: "
                  }
                  phone={board.contact_phone}
                  email={board.contact_email}
                  name={board.contact_name}
                />
              }
            </Card.Text>
          </Card.Body>
        </Card>
        <section>
          <QRCode
            size={150}
            value={website}
          />
        </section>
      </div>
      <div
        className="salon-banner"
        style={{
          backgroundColor: accentColor,
          color: getContrastTextColor(accentColor),
        }}
      >
        <div className="top-text">
          <h2>
            {board.banner_greeting ? board.banner_greeting : "Welcome To"}
          </h2>
        </div>
        <div className="mid-text">
          {board.banner_title ? board.banner_title : board.board_name}
        </div>
        <div className="bottom-text">{"Tap the screen to begin!"}</div>
      </div>
      <div className="screensaver-card-container animate__slideInRight">
        <Featured possibleCards={cards} />
        <Featured possibleCards={cards} />
        <Featured possibleCards={cards} />
      </div>
      <div
        id="screensaverVideoContainer"
        className="screensaver-ad-container video-container"
      >
        {/* <div className="iframe-container "></div> */}
      </div>
      {instaImages.length > 0 ? <ImageSlider images={instaImages} /> : null}
    </div>
  );
}
export default memo(ScreenSaver);
