import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "../pages/pageNotFound";
import Directory from "../pages/salonDirectory/Directory";
import DigitalSignage from "../pages/digitalSignage/DigitalSignage";
import GeneralDirectory from "../pages/generalDirectory/GeneralDirectory";

const Login = lazy(() => import("../pages/auth/Login"));
const NewCard = lazy(() => import("../pages/dash/NewCard"));
const NewGeneralCard = lazy(() => import("../pages/dash/NewGeneralCard"));
const Dashboard = lazy(() => import("../pages/dash/Dashboard"));
const NewBoard = lazy(() => import("../pages/dash/NewBoard"));
const AllBoards = lazy(() => import("../pages/dash/AllBoards"));
const AllUsers = lazy(() => import("../pages/dash/AllUsers"));
const AllCards = lazy(() => import("../pages/dash/AllCards"));
const NewUser = lazy(() => import("../pages/dash/NewUser"));
const Settings = lazy(() => import("../pages/dash/Settings"));
const ClientEditBoard = lazy(() => import("../pages/dash/EditBoard"));
const DeviceIds = lazy(() => import("../pages/dash/DeviceIds"));
const Analytics = lazy(() => import("../pages/dash/Analytics"));
const SuiteInstagram = lazy(() => import("../pages/auth/SuiteInstagram"));
const AssignRooms = lazy(() => import("../pages/dash/AssignRooms"));
const AllContacts = lazy(() => import("../pages/dash/AllContacts"));
const ManageForms = lazy(() => import("../pages/dash/manageForms"));
const Test = lazy(() => import("../pages/Test"));
const InstagramLandingPage = lazy(
  () => import("../pages/auth/InstagramLandingPage")
);
const InstagramAuth = lazy(() => import("../pages/auth/InstagramAuth"));
const InstaPrivacyPolicy = lazy(() => import("../pages/instaPrivacyPolicy"));
const HealthChecker = lazy(() => import("../pages/dash/healthChecker"));

function MyRoutes() {
  return (
    <Routes>
      {/* Admin Routes - lazy loaded */}
      <Route
        path="/"
        element={<Login />}
      />
      <Route
        path="/login"
        element={<Login />}
      />
      <Route
        path="/dashboard"
        element={<Dashboard />}
      />
      <Route
        path="/new-card"
        element={<NewCard />}
      />
      <Route
        path="/cards"
        element={<AllCards />}
      />
      <Route
        path="/new-room"
        element={<NewGeneralCard />}
      />
      <Route
        path="/assign-rooms"
        element={<AssignRooms />}
      />
      <Route
        path="/new-board"
        element={<NewBoard />}
      />
      <Route
        path="/all-boards"
        element={<AllBoards />}
      />
      <Route
        path="/health-checker"
        element={<HealthChecker />}
      />
      <Route
        path="/users"
        element={<AllUsers />}
      />
      <Route
        path="/new-user"
        element={<NewUser />}
      />
      <Route
        path="/edit"
        element={<ClientEditBoard />}
      />
      <Route
        path="/board-settings"
        element={<Settings />}
      />
      <Route
        path="/device-ids"
        element={<DeviceIds />}
      />
      <Route
        path="/analytics"
        element={<Analytics />}
      />
      <Route
        path="/suite-instagram"
        element={<SuiteInstagram />}
      />
      <Route
        path="/all-contacts"
        element={<AllContacts />}
      />
      {/* <Route
        path="/forms"
        element={<ManageForms />}
      /> */}
      <Route
        path="/instagram"
        element={<InstagramLandingPage />}
      />
      <Route
        path="/instagram/auth"
        element={<InstagramAuth />}
      />
      <Route
        path="/instagram-privacy-policy"
        element={<InstaPrivacyPolicy />}
      />
      <Route
        path="/test"
        element={<Test />}
      />

      {/* board routes */}
      <Route
        path="/directory/:dir"
        element={<Directory />}
      />
      <Route
        path="/digital-signage/:dir"
        element={<DigitalSignage />}
      />
      <Route
        path="/general-directory/:dir"
        element={<GeneralDirectory />}
      />

      {/* catch-all route */}
      <Route
        path="*"
        element={<PageNotFound />}
      />
    </Routes>
  );
}
export default MyRoutes;

const allValidPaths = new Set();

MyRoutes().props.children.forEach((route) => {
  const path = route.props.path;

  if (path === "*" || path === "/") return;

  const firstSection = path.split("/")[1];
  allValidPaths.add(firstSection);
});

export { allValidPaths };
