import axios from "axios";
import authHeader from "./auth.header";
const API = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "https://adiuvo-digital-display.herokuapp.com/api/";

class BoardService {
  newBoard(data) {
    return axios.post(API + "board/new", data, { headers: authHeader() });
  }
  getAllBoards(id) {
    return axios.post(API + "boards/all", id, { headers: authHeader() });
  }
  boardInfo(data) {
    return axios.post(API + "board/info", data, { headers: authHeader() });
  }
  getBoardByName(name) {
    return axios.post(API + "board/by-name", name, { headers: authHeader() });
  }
  getBoardById(id) {
    return axios.post(API + "board/by-id", id, { headers: authHeader() });
  }
  updateBoard(data) {
    return axios.post(API + "board/update", data, { headers: authHeader() });
  }
  editBoard(data) {
    return axios.post(API + "board/edit-board", data, {
      headers: authHeader(),
    });
  }
  multiUpload(data) {
    // for (const value of data.values()) {
    //   console.log(value);
    // }
    return axios.post(API + "board/multi-upload", data, {
      headers: {
        "x-access-token": authHeader()["x-access-token"],
        "Content-Type": "multipart/form-data",
      },
    });
  }
  instaImages(data) {
    return axios.post(API + "board/insta-images", data, {
      headers: authHeader(),
    });
  }
  addInstaCode(instaCode, boardId) {
    return axios.post(
      API + "board/add-insta-code",
      { instaCode, boardId },
      {
        headers: authHeader(),
      }
    );
  }
}
export default new BoardService();
