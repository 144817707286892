/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { filterImages } from "../../services/constants";
import { titleCase } from "../../services/helper";

const imageNames = {
  botox: 0,
  boutique: 1,
  facial: 2,
  barber: 3,
  hair: 4,
  laser: 5,
  "lashes & brows": 6,
  nails: 7,
  "wax & tan": 8,
  "massages & body wellness": 9,
  tattoo: 10,
  all: 11,
};

function Featured(props) {
  const [current, setCurrent] = useState("");
  const [possibleCards, setPossibleCards] = useState([]);

  useEffect(() => {
    setPossibleCards(props.possibleCards);
  }, [props.possibleCards]);

  useEffect(() => {
    if (!possibleCards || !possibleCards.length) return;

    const pickNew = () => {
      const random = [...possibleCards].sort(() => 0.5 - Math.random())[0];
      setCurrent(random);
    };

    pickNew();

    let interval = setInterval(() => {
      pickNew();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [possibleCards]);

  const pickLogo = (card) => {
    const defaultImage = filterImages[filterImages.length - 1].image;
    if (!card.categories) {
      return defaultImage;
    }
    if (!card.categories.length) {
      return defaultImage;
    }

    const category = card.categories[0].name.toLowerCase();
    const some = filterImages.some((image) => image.name === category);
    if (!some) return defaultImage;
    const image = filterImages[imageNames[category]].image;

    return image;
  };

  return (
    <div className="featured-cards">
      <div className="card-box">
        <img
          className="featured-img"
          src={current.logo ? current.logo : pickLogo(current)}
        ></img>
        <div className="card-text-container featured-text">
          <p className="featured-text-item">{current.business_name}</p>
          <p className="featured-text-item">{current.contact_name}</p>
        </div>
      </div>
    </div>
  );
}

export default Featured;
