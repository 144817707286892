/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import logo from "./adiuvo-logo.png";
import { Link, useLocation } from "react-router-dom";
import authService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import boardService from "../services/board.service";
import PanicButton from "./PanicButton";

function Navigation() {
  const navigate = useNavigate();
  const [hasSalonDirectory, setHasSalonDirectory] = useState(false);
  const [hasGeneralDirectory, setHasGeneralDirectory] = useState(false);

  let isAdmin;
  let isTech;
  try {
    isAdmin = authService.getCurrentUser().roles.includes("ROLE_ADMIN");
    isTech = authService.getCurrentUser().roles.includes("ROLE_TECH");
  } catch (err) {
    isAdmin = false;
    isTech = false;
  }
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };

  useEffect(() => {
    let active = location.pathname.replace("/", "");
    setActiveLink(active);
  }, [location]);

  useEffect(() => {
    const userId = authService.getCurrentUser()?.id;

    if (userId) {
      boardService
        .getAllBoards({ id: userId })
        .then((res) => {
          const userBoards = res.data;

          if (
            userBoards.some((board) => board.board_type === "Salon Directory")
          )
            setHasSalonDirectory(true);

          if (
            userBoards.some((board) => board.board_type === "General Directory")
          )
            setHasGeneralDirectory(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <div className="nav-container">
      <div className="nav-logo-container">
        {isAdmin && <PanicButton />}
        <img
          src={logo}
          alt="adiuvo logo"
        />
        <button
          onClick={handleLogout}
          className="logout-btn btn"
        >
          Logout
        </button>
      </div>
      <div className="nav-link-container">
        {isAdmin || isTech ? (
          <Dropdown className="nav-dropdown">
            <Dropdown.Toggle className="custom-dropdown-toggle">
              {isAdmin ? "Admin" : "Tech"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {isAdmin && !isTech ? (
                <>
                  <Link
                    className="dropdown-link"
                    to="analytics"
                  >
                    <a className="dropdown-item">Analytics</a>
                  </Link>
                </>
              ) : null}
              <Link
                className="dropdown-link"
                to="/new-user"
              >
                <a className="dropdown-item">New User</a>
              </Link>
              <Link
                className="dropdown-link"
                to="users"
              >
                <a className="dropdown-item">Users</a>
              </Link>
              <Link
                className="dropdown-link"
                to="new-board"
              >
                <a className="dropdown-item">New Board</a>
              </Link>
              <Link
                className="dropdown-link"
                to="/all-boards"
              >
                <a className="dropdown-item">Boards</a>
              </Link>
              <Link
                className="dropdown-link"
                to="/health-checker"
              >
                <a className="dropdown-item">Health Checker</a>
              </Link>
              <Link
                className="dropdown-link"
                to="device-ids"
              >
                <a className="dropdown-item">Device Ids</a>
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {!isTech ? (
          <>
            <Link
              className={`link ${activeLink == "edit" ? "active-link" : null}`}
              to="edit"
            >
              Edit
            </Link>
            {hasSalonDirectory && (
              <>
                <Link
                  className={`link ${
                    activeLink == "new-card" ? "active-link" : null
                  }`}
                  to="new-card"
                >
                  New Card
                </Link>
                <Link
                  className={`link ${
                    activeLink == "cards" ? "active-link" : null
                  }`}
                  to="cards"
                >
                  Cards
                </Link>
              </>
            )}
            {hasGeneralDirectory && (
              <Dropdown className="nav-dropdown">
                <Dropdown.Toggle className="custom-dropdown-toggle">
                  General Directory
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link
                    className="dropdown-link"
                    to="/new-room"
                  >
                    <a className="dropdown-item">New Contact</a>
                  </Link>
                  <Link
                    className="dropdown-link"
                    to="all-contacts"
                  >
                    <a className="dropdown-item">All Contacts</a>
                  </Link>
                  <Link
                    className="dropdown-link"
                    to="assign-rooms"
                  >
                    <a className="dropdown-item">Rename Rooms</a>
                  </Link>
                  {/* <Link
                    className="dropdown-link"
                    to="forms"
                  >
                    <a className="dropdown-item">Manage Forms</a>
                  </Link> */}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        ) : null}
        {/* <Link
                    className={`link ${
                        activeLink == "board-settings" ? "active-link" : null
                    }`}
                    to="board-settings"
                >
                    Settings
                </Link> */}
      </div>
    </div>
  );
}

export default Navigation;
