import React from "react";
import { FaPhone, FaUser, FaEnvelope } from "react-icons/fa";
function Contact({ header, name, phone, email }) {
  return (
    <div className="contact-container">
      <h1 className="info-header alumni">{header}</h1>
      <ul className="list-unstyled grid-list">
        <li>
          <FaUser /> {name}
        </li>
        <li>
          <FaPhone /> {phone}
        </li>
        <li>
          <FaEnvelope /> {email}
        </li>
      </ul>
    </div>
  );
}

export default Contact;
