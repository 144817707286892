import React from "react";

function Filter(props) {
  return (
    <div
      key={props.key}
      onClick={() => props.handleFilter(props.name)}
      className="filterContainer"
    >
      <img className="filter-img" src={props.image} />
    </div>
  );
}

export default Filter;
